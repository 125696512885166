import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import styles from "../styles/menu.module.scss"
import Button from "../components/button"
// import { FaExternalLinkSquareAlt } from "react-icons/fa"
// import { VscFilePdf } from "react-icons/vsc"

const MenuPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            diningMenus {
              slug
              name
              embed
              link
            }
          }
        }
      }
    `
  )

  const { diningMenus: menus } = site.siteMetadata

  return (
    <Layout className={styles.menu}>
      <SEO title="Dining Menu" />
      <Wrapper sizeReadable className="menu-buttons">
        {menus.map(({name, slug}) => (
          <>
            <h2>{name}</h2>
            <Button href={`/menu/${slug}`}>View Menu</Button>
          </>
        ))}
      </Wrapper>
    </Layout>
  )
}

export default MenuPage
